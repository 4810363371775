<template>
  <v-container fluid
    class="mt-3 mx-0 pa-0"
  >
    <!-- イントロ -->
    <v-row id="top" class="py-15 px-15 white">
      <v-col cols="12" md="7"
        class="logo-font my-5 d-flex flex-column align-center justify-center"
      >
        <div v-html="templates.catch_copy" class="text-center"></div>
        <v-sheet class="mt-10">
          <v-chip
            class="px-5 elevation-5"
            pill large
            color="primary"
            :to="{ name: 'signup' }"
          >月額０円ではじめる</v-chip>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="5"
        class="d-flex justify-center"
      >
        <v-img
          src="../assets/logo-system.png"
          :alt="systemName"
          class="rounded"
          :max-width="$vuetify.breakpoint.xsOnly ? '80vw' : '400'"
        ></v-img>
      </v-col>
    </v-row>

    <!-- 特徴 -->
    <v-row id="features" class="pt-12">
      <v-col cols="12" class="text-center">
        <h1 class="accent--text">特徴＆機能</h1>
        <p class="text--secondary text-overline">{{ templates.site_admin_copy }}</p>
      </v-col>
    </v-row>

    <!-- HP管理 -->
    <v-row class="mt-5">
      <v-container
        :class="$vuetify.breakpoint.mdAndDown && 'px-5'"
      >
        <v-row>
          <v-col cols="12" md="6"
            class="d-flex align-center"
          >
            <v-card
              flat tile
              color="transparent"
            >
              <v-card-title class="mt-n7 mb-2 text-h4">
                ホームページDIY・運営
              </v-card-title>
              <v-card-text
                v-html="templates.site_admin_description"
              ></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-img
              src="../assets/home-feature-siteadmin.jpg"
              alt="ホームページ管理スクリーンショット"
              class="rounded"
            ></v-img>
          </v-col>
          <v-col cols="6" md="3">
            <v-img
              src="../assets/home-feature-siteadmin2.jpg"
              alt="ホームページ管理スクリーンショット"
              class="rounded"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <!-- 店舗管理 -->
    <v-row class="mt-15 py-10 white">
      <v-container
        :class="$vuetify.breakpoint.mdAndDown && 'px-5'"
      >
        <v-row>
          <v-col cols="12" class="text-center">
            <p class="text--secondary text-overline">{{ templates.shop_admin_copy }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6"
            :class="$vuetify.breakpoint.mdAndDown && 'order-2'"
          >
            <v-img
              src="../assets/home-feature-shopadmin.png"
              alt="店舗管理スクリーンショット"
              class="rounded"
            ></v-img>
          </v-col>
          <v-col cols="12" md="6"
            class="d-flex align-center"
            :class="$vuetify.breakpoint.mdAndDown && 'order-1'"
          >
            <v-card
              flat tile
              color="transparent"
            >
              <v-card-title class="mt-n7 mb-2 text-h4">
                店舗管理・配信機能
              </v-card-title>
              <v-card-text
                v-html="templates.shop_admin_description"
              ></v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <!-- 料金プラン -->
    <v-row id="plans" class="pt-12">
      <v-col cols="12" class="text-center">
        <h1 class="accent--text">プラン＆料金</h1>
        <p class="text--secondary text-overline">{{ templates.system_plan_copy }}</p>
      </v-col>
    </v-row>

    <v-row class="mt-5 pb-10">
      <v-container
        :class="$vuetify.breakpoint.mdAndDown && 'px-5'"
      >
        <v-row class="mb-5 px-5">
          <v-col cols="12">
            <div
              v-html="templates.system_plan_briefing"
            ></div>
          </v-col>
        </v-row>

        <!-- プラン -->
        <v-row class="px-3">
          <v-col cols="12" md="3"
            class="d-flex"
            v-for="systemPlan in systemPlans"
            :key="systemPlan.id"
          >
              <v-card class="d-flex flex-column pa-3"
                hover
              >
                <v-card-title>
                  <p class="text-h6 font-weight-bold">{{ systemPlan.plan_name }}</p>
                </v-card-title>

                <v-card-text
                  class="flex-shrink-0 flex-grow-1"
                >
                  <pre>{{ systemPlan.description }}</pre>
                </v-card-text>

                <v-card-subtitle>
                  <p>月額：{{ price(systemPlan.monthly_charge) }}</p>
                </v-card-subtitle>
              </v-card>
          </v-col>
          <v-col cols="12">
            <p class="text-caption text--secondary">*金額は全て税別です</p>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="12" class="mb-3">
            <p class="text-h6 accent--text text-center">各プランごとの利用可能な機能とシステムリソースについて</p>
          </v-col>

          <!-- プラン差 -->
          <v-col cols="12">
            <v-card flat tile>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th
                      v-for="systemPlan in systemPlans"
                      :key="systemPlan.id"
                    >
                      {{ systemPlan.plan_name }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="systemPlanPricings.length" >
                  <tr
                    v-for="itemPricing in systemPlanPricings"
                    :key="itemPricing.id"
                  >
                    <td class="item">{{ itemPricing.item }}</td>
                    <td>{{ itemPricing.plan_a }}</td>
                    <td>{{ itemPricing.plan_b }}</td>
                    <td>{{ itemPricing.plan_c }}</td>
                    <td>{{ itemPricing.plan_d }}</td>
                  </tr>
                </tbody>
              </table>
            </v-card>
          </v-col>

          <v-col cols="12"
            class="text-caption text--secondary"
          >
            <div v-html="templates.system_plan_note"></div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <!-- サポート -->
    <v-row id="support" class="py-12 white">
      <v-col cols="12" class="text-center">
        <h1 class="accent--text">サポート</h1>
      </v-col>

      <v-container
        :class="$vuetify.breakpoint.mdAndDown && 'px-5'"
      >
        <v-row>
          <v-col cols="12" md="6"
            class="d-flex align-center"
          >
            <v-card
              flat tile
              color="transparent"
            >
              <v-card-title class="mt-n3 mb-2 text-h4">
                LINEによる迅速サポート
              </v-card-title>
              <v-card-text
                v-html="templates.support_description"
              ></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6"
          >
            <v-img
              src="../assets/support_line_qr.png"
              alt="サポートLINEアカウントQRコード"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import { API_ENDPOINT, SYSTEM_PROVIDER } from "@/literals.js";
import { ApiTool, CheckTokenError } from '@/module.js'

export default {
  name: "Home",

  components: {
  },

  props: {
    isloggedin: {
      type: Boolean,
      default: false
    },
    isLiff: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      systemName: SYSTEM_PROVIDER.systemName,
      credential: {loginId: null, password: null},
      templates: {},
      systemPlans: [],
      systemPlanPricings: [],
      publicApi: new ApiTool(API_ENDPOINT + '/'),
    };
  },

  computed: {
    price() {
      return number => !number ? '無料' : (Number(number).toLocaleString() + '円')
    }
  },

  created() {
    this.getData()
  },

  methods: {
    async getData() {
      await this.setTemplateStrings()

      this.setSystemPlans()
      this.setSystemPlanPricings()
    },

    setTemplateStrings() {
      return this.publicApi.getReqSystemPublic('template-string/', {usedIn: 'home', itemName: ''}).then( results => {
        if (!results || !results.length) return

        results.map( template => {
          this.templates[template.item_name] = template.content
        })
      })
    },

    setSystemPlans() {
      this.publicApi.getReqSystemPublic('system-plan/').then( results => {
        if (!results || !results.length) return
        this.systemPlans = [...results]
      })
      .catch(error => CheckTokenError(error))
    },

    setSystemPlanPricings() {
      this.publicApi.getReqSystemPublic('system-plan-pricing/').then( results => {
        if (!results || !results.length) return
        this.systemPlanPricings = [...results]
      })
      .catch(error => CheckTokenError(error))
    },
  }
}
</script>

<style scoped>
.logo-font {
  line-height: 2.3em;
}
h1 {
  color: #212121;
}
h1 span {
  margin: 0 4px;
}

pre {
  white-space: pre-wrap;
  font-size: 1.1em;
  line-height: 1.6em;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border: solid 1px #e3e3e3;
}
thead tr {
  color: var(--v-accent-base);
  background: var(--v-primary-lighten1);
}
thead th:first-child {
  background-color: var(--content-bg-diff);
}
.theme--dark thead th:first-child {
  background-color: var(--content-bg-dark-diff);
}
tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}
.theme--dark tbody tr:nth-child(even) {
  background-color: #111;
}
th {
  font-size: 1em;
  height: 80px;
  padding: 0 10px;
  text-align: center;
  border: solid 1px #e3e3e3;
}
td {
  height: 70px;
  padding: 0 15px;
  text-align: center;
  color: #666;
  border: solid 1px #e3e3e3;
}
.theme--dark td, .theme--dark th {
  border: solid 1px #333;
}
td.item {
  font-size: .9em;
  font-weight: bold;
  text-align: left;
  color: var(--v-accent-base);
  background-color: var(--v-primary-lighten1);
}
</style>
